<template>
  <div style="height: 100%">
    <div class="yt-breadcrumb-wrapper">
      <el-breadcrumb class="yt-breadcrumb">
        <el-breadcrumb-item>数据分析</el-breadcrumb-item>
        <el-breadcrumb-item>课程分析</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div style="padding: 10px; height: 100%">
      <div class="top">
        <Input class="search-bar" search placeholder="课程名称搜索" @on-search="search" v-model="keyword" />
      </div>
      <el-table height="calc(100% - 131px)" :data="courseData" :loading="loading">
        <el-table-column type="expand" show-overflow-tooltip>
          <template slot-scope="props">
            <CourseExpand :row="props.row" />
          </template>
        </el-table-column>
        <el-table-column prop="name" label="课程名称" show-overflow-tooltip />
        <el-table-column prop="description" label="描述" show-overflow-tooltip />
        <el-table-column prop="studyNum" label="课程学习用户数" show-overflow-tooltip />
      </el-table>
      <Page
        class="yt-page"
        :class="'yt-page-' + (total.toString().length > 1 ? total.toString().length : 2)"
        @on-change="changePage"
        :page-size-opts="[10, 20, 40, 100]"
        @on-page-size-change="changeSize"
        :total="total"
        show-sizer
        show-elevator
        show-total
      />
    </div>
  </div>
</template>

<script>
import CourseExpand from '@components/manage/dataAnalysis/CourseExpand'
import statisticApi from '@api/statistic'
export default {
  name: 'Course',
  components: { CourseExpand },
  data() {
    return {
      keyword: '',
      courseColumn: [
        {
          type: 'expand',
          width: 50,
          render: (h, params) => {
            return h(CourseExpand, {
              props: {
                row: params.row
              }
            })
          }
        },
        {
          title: '课程名称',
          key: 'name',
          ellipsis: true,
          tooltip: true
        },
        {
          title: '描述',
          key: 'description',
          ellipsis: true,
          tooltip: true
        },
        {
          title: '课程学习用户数',
          key: 'studyNum',
          width: 200
        }
      ],
      courseData: [],
      page: 0,
      size: 10,
      th: document.body.clientHeight - 255,
      total: 0,
      loading: false
    }
  },
  created() {
    this.initCourse()
  },
  methods: {
    initCourse() {
      this.loading = true
      statisticApi
        .getCourseA(this.page, this.size, this.keyword)
        .then(res => {
          this.total = res.res.total
          this.courseData = res.res.data
          this.loading = false
        })
        .catch(() => {
          this.loading = false
        })
    },
    changePage(val) {
      this.page = val - 1
      this.initCourse()
    },
    changeSize(val) {
      this.size = val
      this.initCourse()
    },
    search() {
      this.page = 0
      this.size = 10
      this.initCourse()
    }
  }
}
</script>

<style scoped lang="less">
.top {
  height: 50px;
  background: rgba(255, 255, 255, 1);
  box-shadow: 0px 0px 5px rgba(7, 27, 41, 0.1);
  margin: 10px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 50px;
  .search-bar {
    width: 300px;
    ::v-deep .ivu-input {
      border: 0;
      border-bottom: 1px solid #ccc;
      border-radius: 0;
      &:focus {
        box-shadow: 0 1px 0 rgba(45, 140, 240, 0.2);
      }
    }
  }
}
</style>
